.ModalShowBtn__form{

}

.ModalShowBtn__form input{
    margin-bottom: 20px;
    font-family: 'Cygre', 'sans-serif';
    width: 100%;
    height: 60px;
    border-radius: 15px;
    padding: 10px 20px;
    font-size: 1em;
    box-sizing: border-box;
    transition: .2s;
    border: #fbf1f0 2px solid;
}

.ModalShowBtn__form input:focus-visible{
    border: #a41000 2px solid;
    outline: none;
}