html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
    background-color: #F4F4F4 !important;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.ContentTitle{
    width: 1400px;
    margin: 60px auto 30px auto;
    font-weight: 700;
    font-size: 40px;
}

.ContentTitle h2{
    font-weight: 700;
    font-size: 40px;
}


.ContentTitle p{
    font-size: 4em;
    font-weight: bolder;
}

@media screen and (max-width: 1400px) {
    .ContentTitle{
        width: 800px;
        font-weight: 700;
        font-size: 40px;
    }
}

@media screen and (max-width: 810px){

    .ContentTitle{
        width: 300px;
        font-size: 10px;
        margin-top: 40px;
        margin-left: 20px;
    }

    .ContentTitle h2{
        
    }
}

@font-face {
    font-family: 'Cygre';
    src: local('Cygre'),
    url('./fonts/cygre/Cygre-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Cygre';
    src: local('Cygre'),
    url('./fonts/cygre/Cygre-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Cygre-black';
    src: local('Cygre-black'),
    url('./fonts/cygre/Cygre-black.ttf') format('truetype');
    font-weight: normal;
}


.Spinner_box{
    width: 100vw;
    height: 100vh;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10010;
    position: relative
}

.yButton {
    color: rgb(192, 22, 2);
    opacity: 1 !important;
}

.yButtonWave{
    color: rgb(192, 22, 2) !important;
    opacity: 1 !important;
}

.yButtonBackground{
    background-color: rgb(192, 22, 2) !important;
    opacity: 1 !important;
}
