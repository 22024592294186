.OpenRegister{
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100050;
    background-color: #F4F4F4;
}

.OpenRegister__background{
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url("../img/background.png");
    background-size: 1500px;
    background-attachment: fixed;
    opacity: 10%;
    background-color: #F4F4F4;
    z-index: -1;
}

.OpenRegister__text{
    font-family: 'Cygre', 'sans-serif';
    color: #c01602;
    width: 575px;
    text-align: center;
}


.OpenRegister__textTop{
    margin: 60px auto;
    font-size: 44px;
    font-weight: bolder;
}
.OpenRegister__textBottom{
    margin-top: 100px ;
    font-size: 44px;
    margin-bottom: 30px;
}

.OpenRegister__address{
    font-size: 30px;
    color: #696969;
}
.notFoundText_title{
    font-size: 200px;
    color: #a41000;
    font-weight: bold;
    width: 500px;
    text-align: center;
}
.notFoundText{
    font-size: 35px;
    width: 500px;
    text-align: center;
    font-weight: 400;
    color: #313131;
}

.goBackLink__box{
    display: flex;
    justify-content: center;
    width: 100%;
}

.goBackLink{
    color: #a41000;
    font-size: 25px;
    text-align: center;
}

@media screen and (max-width: 1400px){

    .notFoundText_title{
        font-size: 150px;
        width: 300px;
    }
    .notFoundText{
        font-size: 25px;
        width: 300px;
    }

    .OpenRegister__text{
        width: 350px;
    }

    .OpenRegister__textTop, .OpenRegister__textBottom{
        font-size: 25px;
    }
    .OpenRegister__address{
        font-size: 20px;
    }
}

@media screen and (max-height: 680px){
    .OpenRegister__text{
        margin: 40px auto;
    }
    .OpenRegister__textTop{
        font-size: 20px;
    }
    .OpenRegister__textBottom{
        font-size: 18px;
    }
    .OpenRegister__address{
        font-size: 15px;
    }
}
