.portfolioSlider {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-top: 60px;
}

.slideImage {
    width: 340px !important;
    height: 443px !important;
    padding: 10px 0px;
    width: 100%;
    height: auto;
    border-radius: 15px;
    overflow: hidden;
    object-fit: cover;
}