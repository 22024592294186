.product {
    background-color: #F1EDE1;
    padding: 8px;
    border-radius: 5%;
    font-family: "Cygre";
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    text-align: left;
    overflow: hidden;
    width: 453px;
    height: 617px;

    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product:hover{
    transform: scale(1.04) translateY(-3px);
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.33);
}

.product__title {
    padding: 40px 18px 0px;
    align-items: start;
    text-align: start;
    font-family: 'Cygre';
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
}

.product__details {
    padding: 20px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.product__details-button {
    width: 192px;
    height: 52px;
    color: black;
    background-color: #F1EDE1;
    font-weight: 600;
    border-radius: 26px;
    border: 1px solid black;
}

.product__weight-button {
    width: 142px;
    height: 52px;
    background-color: black;
    color: #F1EDE1;
    font-weight: 600;
    border-radius: 26px;
    border: 1px solid black;
}

.product__img {
    position: relative;
    width: 80%;
    top: 20px;
    left: 150px;
    object-fit: cover;
}

@media (max-width: 950px) {
    .product{
        width: 280px;
        height: 375px;
    }

    .product__title {
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        font-size: 26px;
        padding: 20px 10px 0px;
    }

    .product__details {
        padding: 5px 10px;
    }

    .product__details-button {
        font-size: 14px;
        width: 120px;
        height: 32px;
    }

    .product__weight-button{
        font-size: 12px;
        width: 100px;
        height: 32px;
    }

    .product__img {
        left: 100px;
    }
}

@media (max-width: 600px) {
    .product{
        width: 180px;
        height: 250px;
    }

    .product__title {
        font-size: 16px;
        padding: 5px 5px;
        line-height: 16px ;
        margin-bottom: 0px;
    }

    .product__details {
        padding: 0px 5px;
    }

    .product__details-button {
        font-size: 10px;
        width: 80px;
        height: 26px;
    }

    .product__weight-button{
        font-size: 10px;
        width: 60px;
        height: 26px;
    }

    .product__img {
        left: 60px;
    }
}

@media (max-width: 400px) {
    .product{
        width: 160px;
        height: 230px;
    }

    .product__title {
        font-size: 14px;
        padding: 5px 5px;
        line-height: 14px ;
        margin-bottom: 0px;
    }

    .product__details {
        padding: 0px 5px;
    }

    .product__details-button {
        font-size: 8px;
        width: 80px;
    }

    .product__weight-button{
        font-size: 8px;
        width: 50px;
    }

    .product__img {
        left: 40px;
    }
}

