.modal-notify{
    position: fixed;
    right: 20px;
    bottom: 150px;
    width: 100%;
    max-width: 400px;
    border-radius: 15px;
    overflow: hidden;
    background-color: #fff;
    z-index: -5;
    opacity: 0;
}

.modal-notify.active{
    opacity: 1;
    z-index: 1000;
}

.modal-notify__background{
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../FeedbackForm/img/background.png");
    z-index: -1;
    opacity: 6%;
    background-size: contain;
    background-position-y: -5px;
    background-repeat: repeat;
    border-top: 3px solid #C21602;
    border-bottom: 3px solid #C21602;
}

.modal-notify__content{
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.modal-notify__title{
    font-size: 30px;
    font-weight: bold;
    font-family: 'Cygre', 'sans-serif';
    text-align: center;
}

.modal-notify__subtitle{
    font-size: 21px;
    font-family: 'Cygre', 'sans-serif';
    text-align: center;
}

.modal-notify__progress-bar{
    width: 0px;
    height: 5px;
    background-color: #C21602;
    transition: 6s linear;
}

.modal-notify.active .modal-notify__progress-bar{
    width: 100%;
}

.modal-notify__heart{
    display: block;
    height: 40px;
    animation: delicate-pulse 1.8s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.modal-notify__heart-col{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 810px){
    .modal-notify{
        position: fixed;
        right: 0px;
        top: 100px;
        bottom: auto;
    }
}