.FeedbackForm{
    width: 100%;
    height: 760px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.FeedbackForm__background{
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("./img/background.png");
    z-index: -1;
    opacity: 6%;
    background-size: contain;
    background-position-y: -5px;
    background-repeat: repeat;
    border-top: 3px solid #C21602;
    border-bottom: 3px solid #C21602;
}
.FeedbackForm__heart{
    display: block;
    width: 146px;
    height: 125px;
    background-image: url("./img/heart.svg");
    animation: delicate-pulse 1.8s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.FeedbackForm__heart:hover{
    animation: delicate-pulse .8s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);

}

@keyframes delicate-pulse {
    0% { transform: scale(1); }
    15% { transform: scale(1.15); opacity: 90%; }
    30% { transform: scale(1); }
    45% { transform: scale(1.15); opacity: 90%; }
    60% { transform: scale(1); }
    100% { transform: scale(1); }
}

.FeedbackForm__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 700px;
    font-family: 'Cygre', 'sans-serif';
    font-size: 4em;
    font-weight: bolder;
    user-select: none;
}
.FeedbackForm__contentBox{
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.FeedbackForm__form{
    height: 290px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-family: 'Cygre', 'sans-serif';

}
.FeedbackForm__form input{
    font-family: 'Cygre', 'sans-serif';
    width: 575px;
    height: 80px;
    border-radius: 15px;
    padding: 10px 20px;
    font-size: 1em;
    box-sizing: border-box;
    transition: .2s;
    border: #fbf1f0 3px solid;
}

.FeedbackForm__form input:focus-visible{
    border: #a41000 2px solid;
    outline: none;

}


.FeedbackForm__form button{
    font-family: 'Cygre', 'sans-serif';
    width: 575px;
    height: 80px;
    border: none;
    border-radius: 15px;
    font-size: 1.2em;
    background-color: #C21602;
    transition: .2s;
    color: white;
}

.FeedbackForm__form button:hover{
    background-color: #a41000;
}

.FeedbackForm__form button:active{
    transform: scale(.98);
}

@media screen and (max-width: 1400px){
    .FeedbackForm__contentBox{
        width: 800px;
    }
    .FeedbackForm__form{
        height: 210px;
    }
    .FeedbackForm__title{
        font-size: 2em;
        width: 420px;
        margin-right: 40px;
    }
    .FeedbackForm__form input{
        width: 350px;
        height: 60px;
    }
    .FeedbackForm__form button{
        width: 350px;
        height: 60px;
    }
}
@media screen and (max-width: 810px){
    .FeedbackForm{
        margin-top: 0px;
    }
    .FeedbackForm__title{
        flex-direction: column;
        width: 350px;
        margin: 0;
        margin-bottom: 40px;
    }
    .FeedbackForm__title p{
        text-align: center;
        font-size: 40px;
        margin-left: 10px;
    }
    .FeedbackForm__heart {
        width: 106px;
        height: 75px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .FeedbackForm{
        height: 500px;
        margin-bottom: 0;
    }
    .FeedbackForm__contentBox{
        flex-direction: column;
        height: 350px;
    }

    .FeedbackForm__form{
        align-items: center;
        width: 90%;
    }

    .FeedbackForm__form input{
        width: 100%;
        margin-bottom: 10px;
    }

    .FeedbackForm__form button{
        width: 100%;
    }
}