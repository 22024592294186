.video-banner {
    margin-bottom: 30px;
    position: relative;
}

.video-banner video {
    width: 100%;
    height: calc(100vh - 120px);
    object-fit: cover;
    display: block;
}

.video-banner__h1-box{
    position: absolute;
    bottom: 100px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.video-banner h1{
    color: white;
    font-weight: bolder;
    font-size: 40px;
    text-align: center;
}

@media screen and (max-width: 1010px) {
    .video-banner video {
        height: calc(100vh - 194.03px);
    }
}

@media screen and (max-width: 810px) {
    .video-banner video {
        height: calc(100vh - 174.03px);
    }
    .video-banner h1{
        font-size: 26px;
        margin-bottom: 10px;
        max-width: 90%;
    }
}

@media screen and (max-width: 515px) {
    .video-banner video {
        height: calc(100vh - 168.11px);
    }
}