.other-masters__list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .other-masters__card {
    width: 200px;
    text-align: center;
  }

  .Slider__title_v2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
  }