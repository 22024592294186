.Footer{
    margin-top: 80px;
    width: 100%;
    padding: 50px 0 50px;
    background-color: #c21602;
}
.Footer__contentBox{
    max-width: 1360px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Footer__logo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Footer__bottom{
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Footer__nav, .Footer__form, .Footer__contacts{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 280px;
    height: 200px;
    font-family: 'Cygre', 'sans-serif';
    color: white;
}

.Footer__contacts{
    text-align: end;
    align-items: end;
}

.Footer__contacts__phone{
    font-weight: bolder;
    font-size: 1.2em;
}

.Footer__contacts__phone p{
    margin-bottom: 0;
}

.Footer__contacts__time{
    text-align: center;
    color: white;
    font-size: 0.9em;
}

.Footer__socials-row{
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.Footer__socials-img{
    display: block;
    width: 25px;
    height: 25px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.Footer__socials-link{
    background-color: #FFFFFF;
    height: 39px;
    width: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    transition: .1s;
}

.Footer__socials-link:hover{
    transition: .1s;
    transform: scale(.95);
}

.Footer__socials-tg{
    background-image: url('./img/telegram.svg');
}

.Footer__socials-whatsap{
    background-image: url('./img/whatsapp.svg');
}

.Footer__socials-mgr{
    margin-right: 10px;
}

.Footer__nav__link{
    color: white;
    font-size: 1.1em;
    text-decoration: none;
    user-select: none;
}

.Footer__nav__link:hover{
    color: #c5c5c5;
}

.Footer__nav__logo{
    width: 135px;
    height: 45px;
    display: block;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./img/logo_white.svg");
}

@media only screen and (-Webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
    .Footer__nav__logo {
        background-image: url("./img/logo_white.svg");
    }
}

.Footer__social{
    width: 160px;
    display: flex;
    justify-content: space-between;
}
.Footer__social span{
    width: 40px;
    height: 40px;
    display: block;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.Footer__social span:hover{
    transition: .1s;
    transform: scale(.95);
}

.Footer__social__inst{
    background-image: url("./img/inst_white.svg");
    position: relative;
}

.Footer__social__inst::after{
    display: block;
    content: '*';
    color: #ffffff;
    font-family: 'Cygre', 'sans-serif';
    font-size: .8em;
    text-decoration: none;
    right: -8px;
    top: 2px;
    position: absolute;
}

.Footer__social__vk{
    background-image: url("./img/yt_white.svg");
}
.Footer__social__tg{
    background-image: url("./img/tg_white.svg");
}

.Footer__social__description{
    font-size: .8em;
}

.Footer__form{
    display: flex;
    width: 380px;
    flex-direction: column;
    justify-content: flex-end;
}
.Footer__form p{
    margin-bottom: 35px;
    line-height: 25px;
}

.Footer__form input{
    font-family: 'Cygre', 'sans-serif';
    background-color: #C21602;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid white;
    color: white;
    font-size: 1em;
    padding: 5px 0;
}

.Footer__form input::placeholder {
    color: #ffffff;
    opacity: 1; /* Firefox */
}

.Footer__form input:focus-visible{
    outline: none;
}


.Footer__form button{
    background-color: white;
    margin-top: 15px;
    height: 45px;
    color: #C21602;
    border-radius: 15px;
    border: white 1px solid;
    font-family: 'Cygre', 'sans-serif';
    font-weight: bold;
    font-size: 1em;
    overflow: hidden;
    position: relative;
}

.Footer__form button::after{
    position: absolute;
    display: block;
    content: " ";
    top: 0;
    right: -100%;
    width: 70%;
    height: 100%;
    background: linear-gradient(40deg, rgba(255,255,255,0) 18%, rgba(193,46,46,0.6923144257703081) 50%, rgba(255,255,255,0) 80%);
}

.Footer__form button:hover::after{
    transition: 1.1s;
    right: 100%;
}

.Footer__downloadLink{
    color: white;
    font-family: 'Cygre', 'sans-serif';
    line-height: 20px;
    margin-bottom: 15px;
}

@media screen and (max-width: 1400px) {
    .Footer{
        padding-bottom: 0;
    }

    .Footer__top{
        max-width: 660px;
        width: 100%;
    }

    .Footer__bottom{
        flex-direction: column;
    }

    .Footer__contentBox{
        flex-direction: column;
        align-items: center;

    }
    .Footer__nav{
        flex-direction: row;
        align-items: center;
        width: 700px;
        padding: 20px;
        height: auto;
    }
    .Footer__form{
        text-align: center;
        width: 660px;
        margin: 0 auto;
    }

    .Contacts button{
        width: 100%;
    }

    .Footer__contacts{
        align-items: center;
        text-align: center;
        max-width: 660px;
        width: 100%;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .Footer__social{
        max-width: 620px;
        width: 100%;
        padding-top: 60px;
    }

    .Footer__social span{
        width: 90px;
        height: 90px;
    }

}
@media screen and (max-width: 810px){

    .Footer__top{
        width: 90%;
    }

    .Footer__bottom{
        width: 90%;
    }

    .Footer__nav{
        width: 100%;
    }

    .Footer__form{
        width: 100%;
    }

}

@media screen and (max-width: 480px){
    
}