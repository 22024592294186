/* Стили для компонента SalonSlider */
.SalonSlider {
    position: relative;
}

.SalonSlider__card{
    overflow: hidden;
    object-fit: cover;
    width: 361px;
    height: 641px;
}

.SalonSlider__card__img{
    border-radius: 0!important;
    width: 100%;
    height: 100%;
}

.SalonSlider__splide {
    position: relative;
    width: 100%;
}

.SalonSlider__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 1400px;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Cygre', 'sans-serif';
    user-select: none;
}

.SalonSlider__title__text {
    font-size: 40px ;
    font-weight: bold;
}

.SalonSlider__title__arrow {
    width: 170px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 1400px){
.SalonSlider__title  {
    width: 800px;
}
}

@media screen and (max-width: 810px){
    .SalonSlider__splide{
        
    }
    .SalonSlider__title{
        text-align: start;
        width: 90%;
        height: auto;
        padding-bottom: 10px;
    }
    .SalonSlider__title__arrow{
        width: 100px;
    }
    .Slider__title__text{
        max-width: 200px;
        font-size: 20px;
    }
    .Slider__title__arrow{
        
    }
    .Slider__title__arrow{
        height: 50px;
    }
    .Slider__card{
        width: 300px;
        height: 460px;

    }
    .Slider__card div{
        margin-top: 11px;
    }
    .Slider__card__title{
        font-size: 20px;
        line-height: 25px;
    }
    .Slider__card__img_v2{
        width: 300px;
    }
    .Slider__card__description_v2{
        width: 70%;
    }
}