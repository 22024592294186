.Background{
    position: absolute;
    top: 225px;
    width: 100%;
    height: 1500px;
    background-image: url("./img/background.png");
    z-index: -1;
    opacity: 6%;
    background-size: contain;
    background-position-y: -5px;
    background-repeat: repeat;
    border-top: 3px solid #c21602;
    border-bottom: 3px solid #C21602;
}

@media screen and (max-width: 1460px){
    .Background{
        height: 1100px;
    }
}

@media screen and (max-width: 810px){
    .Background{
        height: 1800px;
    }
}

@media screen and (max-width: 600px){
    .Background{
        height: 1150px;
    }
}

@media screen and (max-width: 400px){
    .Background{
        height: 1050px;
    }
}

@media screen and (max-width: 350px){
    .Background{
        height: 1000px;
    }
}