.Header{
    display: flex;
    height: 120px;
    max-width: 1400px;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    font-family: 'Cygre', 'sans-serif';
    user-select: none;
}
.Header__logo{
    background-image: url('./img/logo.svg');
    width: 240px;
    height: 80px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

@media only screen and (-Webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
    .Header__logo {
        background-image: url('./img/logo.svg');
    }
}

.Header__navigate{
    width: 591px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 1400px){
    .Header__navigate{
        width: 500px;
        font-size: 1.1em;
    }
    .Header{
        padding: 0 10px;
    }
}

@media screen and (max-width: 1010px){

    .Header{
        flex-direction: column;
        align-items: stretch;
        height: auto;
    }

    .Header__navigate__link-logo{
        padding-top: 12px;
        padding-left: 10px;
    }


    .Header__navigate{
        margin: 30px auto;
        flex-wrap: wrap; 
        margin-top: 40px;
        width: 100%; 
        padding-left: 20px;
        padding-right: 20px;
    }

    .Header__contact{
        position: absolute;
        top: 30px;
        right: 20px;
        font-size: 14px;
    }
}

@media screen and (max-width: 810px){
    .Header__navigate{
        margin-top: 20px;
    }
    .Header__logo{
        width: 200px;
    }
    .Header{
        flex-direction: column;
        height: auto;
    }
}

@media screen and (max-width: 515px){
    .Header__navigate{
        gap: 5px;
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .Header__logo{
        width: 160px;
    }
}

@media screen and (max-width: 375px){

    .Header__logo{
        width: 130px;
    }

    .Header__contact{
        font-size: 12px;
    }

    .Header__navigate{
        font-size: 11px;
    }

    .Header__logo{
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.Header__navigate__link{
    text-decoration: none;
    color: black;
    font-size: 1.1em;
    user-select: none;
}

.Header__navigate__link::after{
    display: block;
    content: '';
    height: 3px;
    background-color: #c01602;
    margin: auto;
    width: 0;
    transition: .2s;
}

.Header__navigate__link:hover::after{
    width: 100%;
}

.Header__navigate__link-logo{
    transition: 0;
}

.Header__contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Header__contact :nth-child(1){
    color: #C21602;
    font-size: 1.1em;
    font-weight: 600;
    margin: 0px;
}

.Header__contact :nth-child(2){
    color: #B7B7B7;
    font-size: .9em;
}