.BtnBanner{
    max-width: 1400px;
    height: 625px;
    border-radius: 15px;
    background-image: url("./img/banner_2.webp");
    background-position: center;
    background-size: cover;
    margin: 170px auto auto auto;
    font-family: 'Cygre', 'sans-serif';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.BtnBanner div{
    width: 280px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 60px;
}

.BtnBanner_title{
    font-weight: 600;
    font-size: 2.5em;
    margin-bottom: 0;
}

.BtnBanner_paragraph{
    font-size: 1.1em;
    font-weight: 600;
    position: relative;
    top: -10px;
    margin-bottom: 0;
}

@media screen and (max-width: 1400px){
    .BtnBanner{
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 810px){
    .BtnBanner{
        margin-top: 80px;
        margin-left: 0;
        margin-right: 0;
        height: auto;
        border-radius: 0;
    }
    .BtnBanner div{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 70px 40px;
        box-sizing: border-box;
        backdrop-filter: blur(5px);
        border-radius: 15px;
        color: white;
        align-items: center;
    }
    .BtnBanner_paragraph{
        text-align: center;
    }
    .BtnBanner_title {
        margin-bottom: 10px;
    }
}
