/* modalModal.css */
.bold-text{
    font-weight: bold;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(59, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    text-align: left;
}

.modal {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
    background: #F1EDE1;
    padding: 42px 62px;
    width: 1200px;
    max-height: 900px;
    border-radius: 15px;
    max-width: 1200px;
    z-index: 2;
    margin: 0 auto;
}

.modal__title-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal__title {
    align-items: start;
    text-align: start;
    font-family: 'Cygre';
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
}

.modal__close {
    position: relative;
    cursor: pointer;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    z-index: 1;
    left: 20px;
}

.modal__close:hover {
    background: rgba(0, 0, 0, 0.1);
}

.modal__close svg {
    width: 40px;
    height: 40px;
}

.modal__weight{
    width: 120px;
    height: 40px;
    background-color: black;
    color: #F1EDE1;
    border-radius: 26px;
    border: 2px solid black;
    margin-top: 20px;
}

.modal__title-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.modal__description-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    justify-content: space-between;
}

.modal__line {
    max-width: 600px;
    height: 2px;
    background-color: black;
    margin-top: 20px;
}

.modal__description-title {
    font-family: 'Cygre';
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    text-align: left;
    margin-top: 20px;
}

.modal__description-text {
    max-width: 600px;
    font-family: 'Cygre', 'Regular';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.modal__ingredients-title{
    margin-top: 20px;
    font-family: 'Cygre';
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    text-align: left;
}

.modal__ingredients-text{
    white-space: pre-line;
    font-family: 'Cygre';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.modal__alert{
    margin-top: 20px;
    max-width: 600px;
    display: flex;
}

.modal__alert-block{
    padding-left: 20px;
}

.modal__img {
    position: relative;
    width: 30%;
}

@media (max-width: 1200px) {
    .modal{
        margin-left: 10px;
        margin-right: 10px;
    }
}


@media (max-width: 800px) {
    .modal{
        padding: 20px;
    }
    
    .modal__close {
        left: 0;
    }
}

@media (max-width: 767px) {

    .modal__description-block
    {
        display: flex;
        flex-direction: column-reverse; 
        align-items: center;
    }

    .modal__img {
        margin-top: 20px;
        position: relative;
        width: 45%;
    }
}

@media (max-width: 480px) {
    .modal__alert{
        display: flex;
        flex-direction: column;
    }

    .modal__alert-block{
        padding-top: 20px;
        padding-left: 0;
    }

    .modal__img {
        margin-top: 20px;
        position: relative;
        width: 70%;
    }
}