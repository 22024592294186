.AppointmentTextForm{
    font-family: 'Cygre', 'sans-serif';
    height: 340px;
    width: 750px;
    margin: 120px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    user-select: none;
}

.AppointmentTextForm :nth-child(1){
    font-size: 5em;
    font-weight: bolder;
    color: #C21602;
}

.AppointmentTextForm :nth-child(2){
    font-size: 1.5em;
    text-align: center;
    line-height: 35px;
}

@media screen and (max-width: 810px){
    .AppointmentTextForm{
        margin-top: 60px;
        margin-bottom: 60px;
        gap: 0;
        width: 300px;
        height: 250px;
    }
    .AppointmentTextForm p{
        margin-bottom: 0;
    }

    .AppointmentTextForm :nth-child(1){
        font-size: 40px;
    }
    .AppointmentTextForm :nth-child(2){
        font-size: 15px;
        line-height: 20px;
    }
}