.master-detail {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  margin: 0 auto;
  padding: 60px 20px;
  width: 100%;
  gap: 60px;
  align-items: flex-start;
}

.master-detail__img-container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.master-detail__img {
  width: 310px;
  height: 310px;
  object-fit: cover;
  border-radius: 50%;
}

.master-detail__info {
  padding-left: 40px;
  max-width: 75%;

}

.master-detail__title {
  color: #C21602;
  font-size: 40px;
  margin-bottom: 10px;
}

.master-detail__description {
  font-size: 16px;
  margin-bottom: 35px;
  line-height: 1.2;
}

.master-detail__details {
  margin-bottom: 20px;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.master-detail__details-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.master-detail__position p,
.master-detail__experience p {
  font-size: 16px;
  max-width: 80%;
}

.master-detail__position h3{
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 30px;
}

.master-detail__services p {
  font-size: 20px;
  margin-bottom: 10px;
}

.master-detail__services ul {
  list-style-type: none;
  padding: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Два столбца */
  gap: 10px;
}

.master-detail__services ul li {
  font-size: 16px;
  margin-bottom: 5px;
}

.master-detail__button {
  background-color: #C21602;
  color: white;
  padding: 15px 60px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
  max-width: 442px;
  width: 100%;
}

.master-detail__button:hover {
  background-color: #a82222;
}

@media screen and (max-width: 1000px) {
  .master-detail {
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }

  .master-detail__info {
    text-align: center;
    flex-direction: column;
    align-items: center;
    padding: 0;
    max-width: 100%;
  }

  .master-detail__details-container {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .master-detail__details {
    max-width: 100%;
    width: 100%;
    margin-bottom: 0;
  }

  .master-detail__description {
    border-bottom: 1px solid #ccc;
    padding: 20px 20px;
  }

  .master-detail__position {
    flex-direction: column;
    text-align: center;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 0px 20px 20px;
  }

  .master-detail__experience {
    border-bottom: 1px solid #ccc;
    padding: 20px 20px 20px;
  }

  .master-detail__position p, .master-detail__experience p {
    max-width: 100%;
  }

  .master-detail__button {
    margin: 0px auto 0px;
    width: 90%;
  }

  .master-detail__services {
    width: 100%;
    padding: 20px 20px 20px;
    border-top: 1px solid #ccc;
  }

  .master-detail__services ul {
    grid-template-columns: 1fr; /* Одна строка */
    gap: 0;
  }
}