.About{
    max-width: 1400px;
    height: 540px;
    margin: 250px auto auto auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
}
.About__image{
    height: 590px;
    width: 650px;
    background-image: url("./img/girl_1.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}


.About__text{
    width: 730px;
    font-family: 'Cygre', 'sans-serif';
}
.About__title{
    font-size: 4em;
    margin-bottom: 60px;
}
.About__paragraph{
    font-family: 'Cygre', 'sans-serif';
    margin: 30px auto;
    font-size: 1.1em;
    line-height: 28px;
}

.About__text_red{
    color: #C21602;
}

@media screen and (max-width: 1400px) {
    .About__text{
        margin-left: 30px;
    }
    .About__image{
        margin-right: 30px;
    }
}

@media screen and (max-width: 810px){
    .About{
        flex-direction: column-reverse;
        height: auto;
        text-align: center;
        max-height: 1150px;
        margin-top: 0;
    }
    .About__text{
        width: 380px;
        font-size: 15px;
    }
    .About__text{
        margin-left: 0;
    }
    
    .About__text h2{
        margin-bottom: 20px;
    }

    .About__image{
        margin: 0 auto;
        width: 450px;
        background-position: center;
        transform: translateX(-80px);
    }
}

@media screen and (max-width: 440px){
    .About__image{
        width: 400px;
        transform: translateX(-70px);
    }

    .About__text{
        width: 360px;
        font-size: 15px;
    }
}

@media screen and (max-width: 380px){
    .About__image{
        width: 300px;
        transform: translateX(-45px);
    }

    .About__text{
        width: 300px;
        font-size: 15px;
    }
}