.Slider{
    width: 100%;
    overflow: hidden;
}

.Slider__splide{
    width: 1400px;
    margin: 0px auto 0 auto;
}

.Slider .splide__track{
    overflow: initial;
}


.splide__slide img{
    object-fit: cover;
    border-radius: 15px;
}


.Slider__title{
    width: 1400px;
    margin: 0 auto;
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Cygre', 'sans-serif';
    font-size: 1.8em;
    border-bottom: 3px solid #CCCCCC;
    user-select: none;
}

@media screen and (max-width: 1400px) {

    .Slider__splide{
        width: 800px;
    }
    .Slider__title{
        width: 800px;
    }
}

.Slider__title_v2{
    margin-top: 60px;
    border-bottom: none;
    font-size: 2.6em;
    font-weight: bolder;
}

.Slider__title__arrow{
    width: 170px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.splide__arrow{
    width: 70px;
    height: 70px;
    position: initial;
    background-image: url("./img/arrow.svg");
    background-color: #F4F4F4;
    background-position: center;
    background-size: cover;
    transform: translateY(0px);
}

.splide__arrow--next{
    transform: rotate(180deg);
}


.Slider__card{
    width: 315px;
    height: 450px;
    background-color: white;
    border-radius: 15px;
    margin: 40px 0;
    font-family: 'Cygre', 'sans-serif';
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: .2s;
}

.Slider__card  div{
    margin: 20px auto;
}


.Slider__card:hover{
    transform: scale(1.04) translateY(-10px);
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.33);
}

.Slider__card__img{
    width: 280px;
    height: 280px;
    margin-bottom: 20px;
    border-radius: 15px;
    object-fit: cover;

}
.Slider__card__title{
    font-size: 1.14em;
    width: 250px;
    line-height: 25px;
}

.Slider__card__coast{
    color: #C21602;
    font-size: 1.3em;
    align-self: end;
    margin: 15px 20px;
    position: absolute;
    bottom: 0;
}

.Slider__card_v2{
    height: 640px;
    border-bottom: 2px solid #C21602;
    font-family: 'Cygre', 'sans-serif';
    transition: .2s;
}

.Slider__card_v2:hover{
    transform:  translateY(-10px);
}

.Slider__card__title_v2{
    color: #C21602;
    font-weight: bolder;
    font-size: 1.5em;
    margin: 30px 0;
}

.Slider__card__description_v2{
    width: 60%;
    line-height: 25px;
    height: 50px;
    margin-bottom: 0;
}

.Slider__card__experience_v2{
    line-height: 25px;
}


.Slider__card__img_v2{
    width: 340px;
    height: 425px;
    border-radius: 15px;
    object-fit: cover;
}


.Slider__card__button_v2{
    width: 100%;
    height: 50px;
    color: white;
    border-radius: 28px;
    background-color: #C21602;
    margin-top: 10px;
    border: none;
    font-size: 1em;
    transition: .2s;
    z-index: 100;
}

@media screen and (max-width: 810px){

    .Slider__title{
        text-align: left;
    }

    .Slider__title__text{
        font-size: 40px;
    }
    
    .Slider__title_v2 .Slider__title__text{
        font-size: 40px !important;
    }

    .Slider{
       left: 20px;
    }

    .Slider__splide{
        width: 100%;
        padding-left: 20px;
    }
    .Slider__title{
        width: 98%;
        height: auto;
        padding-bottom: 10px;
        padding-right: 20px;
    }
    .splide__arrow{
        width: 40px;
        height: 40px;
    }
    .Slider__title__text{
        font-size: 20px;
    }
    .Slider__title__arrow{
        width: 100px;
    }
    .Slider__title__arrow{
        height: 50px;
    }
    .Slider__card{
        width: 300px;
        height: 460px;

    }
    .Slider__card div{
        margin-top: 11px;
    }
    .Slider__card__title{
        font-size: 20px;
        line-height: 25px;
    }
    .Slider__card__img_v2{
        width: 300px;
    }
    .Slider__card__description_v2{
        width: 70%;
    }

    .splide__slide.is-active.is-visible {
        
    }
}