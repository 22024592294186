.ModalShowBtn{
    width: 280px;
    height: 55px;
    color: white;
    border-radius: 28px;
    background-color: #C21602;
    border: none;
    font-size: 1em;
    transition: .2s;
}

.ModalShowBtn:hover{
    background-color: #a41000;
}

.ModalShowBtn:active{
    transform: scale(.98);
}