.Contacts{
    max-width: 1350px;
    margin: 60px auto;
    display: flex;
    justify-content: space-between;
    font-family: 'Cygre', 'sans-serif';
}
.Contacts__map{
    width: 630px;
    height: 555px;
    border-radius: 15px;
    border: #ffe1df 1px solid;
}
.Contacts__title{
    font-size: 2.6em;
    font-weight: bolder;
    margin-bottom: 30px;
    user-select: none;
}
.Contacts__contactsBox{
    width: 660px;
    height: 225px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    user-select: none;
}
.Contacts__contactsBox a{
    color: black;
    text-decoration: none;
    box-sizing: content-box;
    width: 280px;
}

.Contacts__contact{
    display: flex;
    height: 100px;
    align-items: center;
}

.Contacts__contact__title, .Contacts__social__title{
    font-weight: bolder;
    margin-bottom: 15px;
    font-size: 1.2em;
}

.Contacts__social__title{
    font-size: 20px !important;
}

.Contacts__contact span{
    display: block;
    height: 35px;
    width: 35px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 20px;
}

.Contacts__phone span{
    background-image: url("./img/phone.svg");
}

.Contacts__address span{
    background-image: url("./img/location.svg");
}
.Contacts__email span{
    background-image: url("./img/mail.svg");
}
.Contacts__time span{
    background-image: url("./img/time.svg");
}

.Contacts__social__linkBox{
    width: 160px;
    display: flex;
    justify-content: space-between;
}

.Contacts__social__linkBox span{
    display: block;
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.Contacts__social__linkBox span:hover{
    transition: .1s;
    transform: scale(.95);
}

.Contacts__social__inst{
    background-image: url("./img/inst.svg");
    position: relative;
}
.Contacts__social__inst::after{
    display: block;
    content: '*';
    color: #a41000;
    font-family: 'Cygre', 'sans-serif';
    font-size: .8em;
    text-decoration: none;
    right: -8px;
    top: 2px;
    position: absolute;
}

.text_red{
    color: #C21602;
}

.Contacts__social__vk{
    background-image: url("./img/yt.svg");
}
.Contacts__social__tg{
    background-image: url("./img/tg.svg");
}

.Contacts__social__description{
    margin: 14px 0 30px 0;
}

.Contacts button{
    font-family: 'Cygre', 'sans-serif';
    width: 100%;
    height: 80px;
    margin: 0 auto;
    border: none;
    border-radius: 15px;
    font-size: 1.2em;
    background-color: #C21602;
    transition: .2s;
    color: white;
}

.Contacts button:hover{
    background-color: #a41000;
}

.Contacts button:active{
    transform: scale(.98);
}

.Contacts__contact{
    font-size: 15px;
    align-items: flex-start;
    margin: 0;
}

.Contacts__contact span{
    width: 25px;
    height: 25px;
}

.mapContainer > div{
    border-radius: 15px ;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.ymaps-2-1-79-ground-pane{
    filter: grayscale(100%);
}

@media screen and (max-width: 1400px) {

    .Contacts{
        flex-direction: column-reverse;
        align-items: center;
    }

    .mapContainer > div{
        width: 500px;
        height: 80%;
        margin: 80px auto;
    }
    
}





@media screen and (max-width: 810px){

    .Contacts{
        width: 800px;
    }

    

    .mapContainer{
        width: 100%;
    }

    .Contacts{
        width: 100%;
    }
    .Contacts__map{
        margin-bottom: 80px;
        width: 100vw;
        border-radius: 0;
        box-sizing: border-box;
    }
    .Contacts__contactsBox{
        width: 90vw;
        height: auto;
    }
    .Contacts__contact{
        font-size: 15px;
        align-items: flex-start;
        margin: 0;
    }
    .Contacts__contact a{
        text-decoration: none;
        color: black;
    }
    .Contacts__contact span{
        width: 25px;
        height: 25px;
    }
    .Contacts__social__title{
        font-size: 15px;
    }
    .Contacts__social__linkBox{
        width: 100%;
        margin: 40px 0;
    }
    .Contacts__social__linkBox span{
        width: 90px;
        height: 90px;
    }
    .Contacts__social__description{
        font-size: 12px;
    }
    .Contacts button{
        height: 50px;
    }
}

.ymaps-2-1-79-svg-icon{
    background-image: url("./img/map_icon.png") !important;
    width: 70px !important;
    height: 40px !important;
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat;
    transform: rotate(33deg);
}