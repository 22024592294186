.modal-2{
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal-2.active{
    transform: scale(1);
    opacity: 1;
    pointer-events: all;
}

.modal-2__content{
    position: relative;
    background-color: #fff;
    transition: 0.5s;
    transform: scale(0);
    border-radius: 15px;
    width: 100%;
    max-width: 618px;
    margin: 10px;
}

.modal-2.active .modal-2__content{
    transform: scale(1);
}

.footer-feedback-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 31px;
    padding-top: 70px;
}

.footer-feedback-form__title{
    font-size: 36px;
    font-weight: 700;
    color: #000;
    line-height: 1;
    font-family: 'Cygre', 'sans-serif';
    text-align: center;
}

.footer-feedback-form__subtitle{
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    color: #000;
    margin-top: 30px;
    margin-bottom: 60px;
    text-align: center;
    max-width: 413px;
    font-family: 'Cygre', 'sans-serif';
}

.footer-feedback-form input{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 83px;
    width: 100%;
    max-width: 558px;
    background-color: #F4F4F4;
    border: none;
    outline: none;
    border-radius: 15px;
    font-size: 16px;
    font-family: 'Cygre', 'sans-serif';
    margin-bottom: 25px
}

.footer-feedback-form__btn{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 83px;
    width: 100%;
    max-width: 558px;
    background-color: #C21602;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 700;
    transition: .2s;
    letter-spacing: 0.4px;
    font-family: 'Cygre', 'sans-serif';
}

.footer-feedback-form__btn:hover{
    background-color: #a41000;
}


.footer-feedback-form__close-btn{
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 20px;
    font-size: 40px;
    line-height: 1;
}

@media screen and (max-width: 810px){
    .footer-feedback-form input {
        height: 60px;
        margin-bottom: 18px;
    }

    .footer-feedback-form__btn {
        height: 60px;
    }

    .footer-feedback-form__subtitle {
        margin-top: 25px;
        margin-bottom: 40px;
    }
}