.slide{
    width: 450px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    margin: 5px 0;
    font-family: 'Cygre', 'sans-serif';
    font-size: 1.15em;
    text-align: center;
    padding: 0 20px;
    transition: .5s;
}
.slide:hover{
    background-color: #fff1f1;
}

@media screen and (max-width: 810px){
    .slide{
        width: 250px;
        height: 70px;
        font-size: 12px;
        border-radius: 15px;
    }
}