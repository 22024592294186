.SimpleBanner{
    background-image: url("img/banner.png");
    background-position: center;
    background-size: cover;
    max-width: 1400px;
    height: 623px;
    margin: auto;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 0 30px 30px 30px;

}

.SimpleBanner h1{
    color: white;
    font-weight: bolder;
    font-size: 40px;
    text-align: center;
}


@media screen and (max-width: 1400px){
    .SimpleBanner{
        margin-left: 10px;
        margin-right: 10px;
    }
}
@media screen and (max-width: 810px){
    .SimpleBanner{
        height: 400px;
        margin-left: 0;
        margin-right: 0;
        padding: 0 10px 10px 10px;
        border-radius: 0;
        align-items: flex-end;
    }

    .SimpleBanner.visible {
        background-image: url("img/banner-mob.png"); 
    }

    .SimpleBanner h1{
        font-size: 26px;
        margin-bottom: 10px;
        max-width: 90%;
    }
}

@media screen and (max-width: 810px){
    .SimpleBanner{
        background-image: url("img/banner-mob.png");
        width: 390px;
        height: 530px;
        margin: 0 auto; 
        border-radius: 15px;
    }
}




@media screen and (max-width: 400px){
    .SimpleBanner{
        background-image: url("img/banner-mob.png");
        height: 500px;
        width: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
}