.menuBar {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-family: 'Cygre', 'sans-serif';
}

.menuBar__contentBox {
    max-width: 535px;
    margin: 58px auto;
}

.menuBar__main {
    max-width: 535px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.menuBar__title {
    font-family: 'Cygre-black', 'sans-serif';
    color: #C21602;
    font-size: 96px;
    font-weight: 900;
    text-align: left;
}

.menuBar__subtitle {
    position: relative;
    color: #C21602;
    font-size: 80px;
    font-weight: 700;
    text-align: left;
    top: -50px;
}

.menuBar__heart {
    display: block;
    position: relative;
    top: -30px;
    width: 146px;
    height: 125px;
    padding-left: 20px;
    animation: delicate-pulse 1.8s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.menuBar__description {
    font-family: 'Cygre';
    font-size: 22px;
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    color: #000000;
}

.products__contentBox {
    max-width: 1400px;
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    gap: 20px;
}


@media (max-width: 1400px) {
    .products__contentBox {
        justify-content: center;
    }
}

@media (max-width: 600px) {

    .menuBar__contentBox{
        display: flex;
        flex-direction: column; 
        justify-content: flex-end;
        margin: 0px auto;
        height: 531px;
        max-width: 391px;
        width: 90%;
        background-image: url("../img/banner.png");
        padding: 8px;
        background-size: contain;
        background-repeat: no-repeat; 
        background-position: center;
        border-radius: 15px;
    }

    .menuBar__main {
        max-width: 310px;
        flex-direction: column-reverse;
    }

    .menuBar__title{
        max-width: 310px;
        font-size: 56px;
        text-align: center;
    }

    .menuBar__subtitle{
        max-width: 310px;
        font-size: 56px;
        top: -25px;
        text-align: center;
    }

    .menuBar__heart{
        display: none;
    }

    .menuBar__description {
        font-size: 15px;
        line-height: 20px;
    }
    
}

@media (max-width: 425px) {

    .menuBar__contentBox{
        height: 430px;
        max-width: 320px;
    }

    .menuBar__title{
        font-size: 36px;
    }

    .menuBar__subtitle{
        font-size: 36px;
    }

    .menuBar__description {
        font-size: 12px;
    }
}