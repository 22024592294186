.portfolioSlider{
    margin-bottom: 100px;
}

.Slider__title_v2{
    max-width: 1400px;
    margin: 0 auto;
}

.slideImagePortfolio{
    width: 340px !important;
    height: 443px !important;
    padding: 10px 0px;
    border-radius: 15px;
    overflow: hidden;
    object-fit: contain;
    object-position: center;
}

.slideImagePortfolio img {
    width: 100%; 
    height: 100%; 
    object-fit: cover;
    object-position: center; 
    border-radius: 15px;
}

@media screen and (max-width: 1000px) {
    .portfolioSlider{
        margin-bottom: 20px;
    }
}