.Reviews{
  max-width: 1360px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 30px;
  padding-right: 30px;
}

.Reviews .SalonSlider__title__text{
  margin-bottom: 25px;
}

.review-lab {
    font-family: Arial, sans-serif;
    color: #333;
  }

  review-lab .widget__header{
    margin: 0 !important;
  }

  review-lab .widget{
    max-width: 1360px !important;
  }

  .khdghj{
    background-color: none !important;
  }

  review-lab .ngucarousel {
    padding: 0 !important;
  }

  review-lab .widget__tabs{
    background-color: transparent  !important;
    box-shadow: none !important;
    padding: 10px!important;
  }
  
  widget-review .review{
    padding: 20px 26px !important;
  }

  .review__text{
    font-family: 'Cygre', 'sans-serif' !important;
  }

  widget-review .review__title>span{
    font-size: 18px !important;
    font-weight: bold !important;
  }
  
  widget-review .review__body p{
    font-size: 14px !important;
    line-height: 24px !important;
    color: black !important;
  }

  widget-review .review__rating .icon_active {
    height: 16px !important;
    width: 16px !important;
    fill: red !important;
  }

  review-lab .widget__footer{
    display: none !important;
  }

@media (max-width: 900px){
    review-lab .widget__header{
        padding: 0 6px !important;
    }
    review-lab .widget {
        padding: 16px !important;
    }

    .Reviews{
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .Reviews .SalonSlider__title__text{
      margin-left: 10px;
    }

}

